import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import { ReuseImgTag } from './ReuseImgTag'
import { ButtonCompact } from './Button'

const BlockDownloadRightWapper = styled.div`
  h2 {
    /* color: rgb(15, 33, 55); */
    color: #047cc2;
    font-size: 2rem;
  }

  p {
    /* color: rgba(52, 61, 72, 0.8); */
    color: #071741;
    font-size: 1.2rem;
    line-height: 1.4;
  }
  button {
    font-size: 1.2rem;
  }
  button:focus {
    outline: none;
  }
`

export const BlockDownloadRight = (props: any) => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    // Percentage of item in view to trigger animation
    threshold: 0.25,
  })

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <BlockDownloadRightWapper>
      <motion.div
        className={props.MainContainer}
        ref={ref}
        animate={controls}
        initial='hidden'
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 25 },
        }}
        transition={{ ease: 'easeOut', duration: 1.25, delay: 0.35 }}>
        <div className={props.ImageContainer}>
          <ReUseHTwotag HeadingStyle={props.smallScreen} Heading={props.RightHeading} />
          <div className={props.ControlSet}>
            <div className={props.ImageSet}>
              <ReuseImgTag ImagePath={props.RightImagePath} AltName={props.AltName} ImageStyle={props.ImageCon} />
            </div>
            <div className={props.contentContrainer}>
              <ReUseHTwotag HeadingStyle={props.BigScreen} Heading={props.RightHeading} />
              <ReUsePtag para={props.RightPara} paraStyle={props.StylePara} />
              <div className={props.ButtonContrainer}>
                <a href={props.RightPdf} download>
                  {/* <button className={props.Buttontext}>{props.ButtonName}</button> */}
                  <ButtonCompact cta={props.ButtonName} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </BlockDownloadRightWapper>
  )
}
