import React from 'react'
import styled from 'styled-components'
import { BlockDownloadLeft } from './BlockDownloadLeft'
import ContractsBannerData from '../Data/ContractsBannerData.json'

const AppContractsBannerWapper = styled.div`
  .BannerContainer {
    padding: 3%;
    margin: auto;
    max-width: 1920px;
    padding-top: 120px;
  }
  .LeftBanner {
    display: flex;
    justify-content: space-around;
    @media (max-width: 1101px) {
      flex-flow: wrap;
    }
  }
  .BannerContentContainer {
    margin-top: 5%;
    @media (max-width: 1450px) {
      margin-top: 4%;
    }
    @media (max-width: 1390px) {
      margin-top: 2%;
    }
    @media (max-width: 1101px) {
      margin-top: 4%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .Bannerheading {
    text-align: center;
  }
  .BigBannerpara {
    text-align: left;
    font-size: 1.2rem;
    max-width: 650px;
    @media (max-width: 1101px) {
      display: none;
    }
  }
  .BannerButtonContainer {
    display: flex;
    justify-content: center;
  }
  .BannerButtonText {
    display: none;
  }
  .BannerImageContainer {
    width: 40%;
    @media (max-width: 1101px) {
      width: 100%;
    }
  }
  .BannerImage {
    height: auto;
    width: 70%;
    box-shadow: inset 32px 7px 90px #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    float: right;
    border-radius: 35px;
    @media (max-width: 1101px) {
      width: 100%;
      float: unset;
    }
  }
  .MobileBannerHeading {
    display: none;
    @media (max-width: 1101px) {
      text-align: center;
      display: block;
    }
    @media (max-width: 420px) {
      text-align: left;
    }
  }
  .MobileBannerbutton {
    display: none;
    justify-content: center;
    @media (max-width: 1101px) {
      display: flex;
      justify-content: center;
    }
  }
  .BannerButtondec {
    text-decoration: none;
  }
  .MobileButtonBannerText {
    display: none;
  }
`

export const AppContractsBanner = () => {
  return (
    <AppContractsBannerWapper>
      {ContractsBannerData.map((data, index) => {
        return (
          <div key={index}>
            <BlockDownloadLeft
              Heading={data.title}
              Para={data.para}
              ImagePath={data.ImagePath}
              MainContainer='BannerContainer'
              LagerContainer='LeftBanner'
              ContentContainer='BannerContentContainer'
              StyleHeading='Bannerheading'
              StylePara='BigBannerpara'
              ButtonContainer='BannerButtonContainer'
              ButtonText='BannerButtonText'
              ImageContainer='BannerImageContainer'
              StyleImage='BannerImage'
              MobileHeading='MobileBannerHeading'
              MobileButton='MobileBannerbutton'
              Decoration='BannerButtondec'
              MobileButtonText='MobileButtonBannerText'
            />
          </div>
        )
      })}
    </AppContractsBannerWapper>
  )
}
