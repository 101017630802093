import React from 'react'
import styled from 'styled-components'
import { BlockDownloadRight } from './BlockDownloadRight'
import LegalAcceptableUsePolicy from '../Data/LegalAcceptableUsePolicy.json'

const AppLegalAcceptableUsePolicyWpper = styled.div`
  .AcceptableContainer {
    margin: auto;
    max-width: 1920px;
  }
  .RightAcceptableContainer {
    padding: 3%;
  }
  .MobileAcceptableHeading {
    display: none;
    @media (max-width: 1101px) {
      text-align: center;
      display: block;
    }
  }
  .RightAcceptable {
    display: flex;
    justify-content: space-around;
    @media (max-width: 1101px) {
      flex-flow: wrap;
    }
  }
  .AcceptableImageContainer {
    width: 40%;
    /* @media (max-width: 1750px) {
      width: 70%;
    } */
    @media (max-width: 1101px) {
      width: 100%;
    }
  }
  .AcceptableImage {
    height: auto;
    box-shadow: inset 32px 7px 90px #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 35px;
    width: 70%;
    /* @media (max-width: 1750px) {
      width: 70%;
    } */
    @media (max-width: 1101px) {
      width: 100%;
    }
  }
  .AcceptableContentContainer {
    margin-top: 8%;
    @media (max-width: 1750px) {
      padding-right: 6%;
    }
    @media (max-width: 1450px) {
      margin-top: 6%;
    }

    @media (max-width: 1101px) {
      margin-top: 4%;
      margin-left: auto;
      margin-right: auto;
      padding-right: auto;
    }
  }
  .AcceptableButtonContainer {
    display: flex;
    justify-content: center;
  }
  .AcceptableButtonText {
    width: 250px;
    height: 50px;
    background-color: #34a1d5;
    border: none;
    color: #fff;
    border-radius: 10px;
  }
  .BigAcceptableHeading {
    text-align: center;
    @media (max-width: 1101px) {
      display: none;
    }
  }
`

export const AppLegalAcceptableUsePolicy = () => {
  return (
    <AppLegalAcceptableUsePolicyWpper>
      {LegalAcceptableUsePolicy.map((data, index) => {
        return (
          <div key={index}>
            <BlockDownloadRight
              RightHeading={data.title}
              RightPara={data.para}
              ButtonName={data.ButtonName}
              RightPdf={data.PdfPath}
              RightImagePath={data.ImagePath}
              MainContainer='AcceptableContainer'
              ImageContainer='RightAcceptableContainer'
              smallScreen='MobileAcceptableHeading'
              ControlSet='RightAcceptable'
              ImageSet='AcceptableImageContainer'
              ImageCon='AcceptableImage'
              contentContrainer='AcceptableContentContainer'
              BigScreen='BigAcceptableHeading'
              ButtonContrainer='AcceptableButtonContainer'
              Buttontext='AcceptableButtonText'
            />
          </div>
        )
      })}
    </AppLegalAcceptableUsePolicyWpper>
  )
}
