import React from 'react'
import styled from 'styled-components'
import { BlockDownloadLeft } from './BlockDownloadLeft'
import LegalCustomerData from '../Data/LegalCustomerData.json'

const AppLegalCustomerWapper = styled.div`
  .CustomerContainer {
    padding: 3%;
    margin: auto;
    max-width: 1920px;
  }
  .CustomerLeft {
    display: flex;
    justify-content: space-around;
    @media (max-width: 1101px) {
      flex-flow: wrap;
    }
  }
  .CustomerContentContainer {
    margin-top: 5%;
    @media (max-width: 1101px) {
      margin-top: 4%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .Customerheading {
    text-align: center;
  }
  .BigCustomerpara {
    text-align: left;
    max-width: 800px;
    @media (max-width: 1101px) {
      display: none;
    }
  }
  .CustomerButtonContainer {
    display: flex;
    justify-content: center;
  }
  .CustomerButtonText {
    @media (max-width: 1101px) {
      display: none;
    }
  }
  .CustomerImageContainer {
    width: 40%;
    @media (max-width: 1101px) {
      width: 100%;
    }
  }
  .CustomerImage {
    height: auto;
    box-shadow: inset 32px 7px 90px #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 35px;
    float: right;
    width: 85%;
    @media (max-width: 1750px) {
      width: 80%;
    }
    @media (max-width: 1101px) {
      width: 100%;
      float: unset;
    }
  }
  .MobileCustomerheading {
    display: none;
    @media (max-width: 1101px) {
      text-align: center;
      display: block;
    }
    @media (max-width: 420px) {
      text-align: left;
    }
  }
  .MobileCustomerButton {
    display: none;
    justify-content: center;
    @media (max-width: 1101px) {
      display: flex;
      justify-content: center;
    }
  }
  .CustomerButtondec {
    text-decoration: none;
  }
  .CustomerMobButtonText {
    display: none;
    @media (max-width: 1101px) {
      display: block;
    }
  }
`

export const AppLegalCustomer = () => {
  return (
    <AppLegalCustomerWapper>
      <div>
        {LegalCustomerData.map((data, index) => {
          return (
            <div key={index}>
              <BlockDownloadLeft
                Heading={data.title}
                Para={data.para}
                ButtonName={data.ButtonName}
                ImagePath={data.ImagePath}
                PdfPath={data.PdfPath}
                MainContainer='CustomerContainer'
                LagerContainer='CustomerLeft'
                ContentContainer='CustomerContentContainer'
                StyleHeading='Customerheading'
                StylePara='BigCustomerpara'
                ButtonContainer='CustomerButtonContainer'
                ButtonText='CustomerButtonText'
                ImageContainer='CustomerImageContainer'
                StyleImage='CustomerImage'
                MobileHeading='MobileCustomerheading'
                MobileButton='MobileCustomerButton'
                Decoration='CustomerButtondec'
                MobileButtonText='CustomerMobButtonText'
              />
            </div>
          )
        })}
      </div>
    </AppLegalCustomerWapper>
  )
}
