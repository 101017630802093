import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import styled from 'styled-components'
import { ButtonCompact } from './Button'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import { ReuseImgTag } from './ReuseImgTag'

const BlockDownloadLeftWapper = styled.div`
  h2 {
    /* color: rgb(15, 33, 55); */
    color: #047cc2;
    font-size: 2rem;
  }

  p {
    /* color: rgba(52, 61, 72, 0.8); */
    color: #071741;
    font-size: 1.2rem;
    line-height: 1.4;
  }
  button {
    font-size: 1.2rem;
  }
  button:focus {
    outline: none;
  }
`

export const BlockDownloadLeft = (props: any) => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    // Percentage of item in view to trigger animation
    threshold: 0.25,
  })

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <BlockDownloadLeftWapper>
      <motion.div
        ref={ref}
        animate={controls}
        initial='hidden'
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 25 },
        }}
        transition={{ ease: 'easeOut', duration: 1.25, delay: 0.35 }}>
        <div className={props.MainContainer}>
          <div className={props.LagerContainer}>
            <div className={props.ContentContainer}>
              <ReUseHTwotag HeadingStyle={props.StyleHeading} Heading={props.Heading} />
              <ReUsePtag para={props.Para} paraStyle={props.StylePara} />
              <div className={props.ButtonContainer}>
                <a href={props.PdfPath} download>
                  <div className={props.ButtonText}>
                    {/* <button className={props.ButtonText}>{props.ButtonName}</button> */}
                    <ButtonCompact cta={props.ButtonName} />
                  </div>
                </a>
              </div>
            </div>
            <div className={props.ImageContainer}>
              <ReuseImgTag ImagePath={props.ImagePath} AltName={props.AltName} ImageStyle={props.StyleImage} />
            </div>
          </div>
          <p className={props.MobileHeading}>{props.Para}</p>
          <div className={props.MobileButton}>
            <a href={props.PdfPath} download className={props.Decoration}>
              {/* <button >{props.ButtonName}</button> */}
              <div className={props.MobileButtonText}>
                <ButtonCompact cta={props.ButtonName} />
              </div>
            </a>
          </div>
        </div>
      </motion.div>
    </BlockDownloadLeftWapper>
  )
}
