import React from 'react'
import styled from 'styled-components'
import { BlockDownloadRight } from './BlockDownloadRight'
import LegalProData from '../Data/LegalProData.json'

const AppLegalDataProcessingAgreementWpper = styled.div`
  .DataContainer {
    margin: auto;
    max-width: 1920px;
  }
  .RightDataContainer {
    padding: 3%;
  }
  .MobileDataHeading {
    display: none;
    @media (max-width: 1101px) {
      text-align: center;
      display: block;
    }
  }
  .RightData {
    display: flex;
    justify-content: space-around;
    @media (max-width: 1101px) {
      flex-flow: wrap;
    }
  }
  .DataImageContainer {
    width: 40%;

    @media (max-width: 1101px) {
      width: 100%;
    }
  }
  .DataImage {
    height: auto;
    box-shadow: inset 32px 7px 90px #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 35px;
    width: 70%;
    /* @media (max-width: 1750px) {
      width: 80%;
    } */
    @media (max-width: 1101px) {
      width: 100%;
    }
  }
  .DataContentContainer {
    margin-top: 8%;
    @media (max-width: 1750px) {
      margin-top: 6%;
    }
    @media (max-width: 1450px) {
      margin-top: 4%;
    }
    @media (max-width: 1101px) {
      margin-top: 4%;
      margin-left: auto;
      margin-right: auto;
      padding-right: auto;
    }
  }
  .DataButtonContainer {
    display: flex;
    justify-content: center;
  }
  .DataButtonText {
    width: 250px;
    height: 50px;
    background-color: #34a1d5;
    border: none;
    color: #fff;
    border-radius: 10px;
  }
  .BigDataHeading {
    text-align: center;
    @media (max-width: 1101px) {
      display: none;
    }
  }
`

export const AppLegalDataProcessingAgreement = () => {
  return (
    <AppLegalDataProcessingAgreementWpper>
      {LegalProData.map((data, index) => {
        return (
          <div key={index}>
            <BlockDownloadRight
              RightHeading={data.title}
              RightPara={data.para}
              ButtonName={data.ButtonName}
              RightPdf={data.PdfPath}
              RightImagePath={data.ImagePath}
              MainContainer='DataContainer'
              ImageContainer='RightDataContainer'
              smallScreen='MobileDataHeading'
              ControlSet='RightData'
              ImageSet='DataImageContainer'
              ImageCon='DataImage'
              contentContrainer='DataContentContainer'
              BigScreen='BigDataHeading'
              ButtonContrainer='DataButtonContainer'
              Buttontext='DataButtonText'
            />
          </div>
        )
      })}
    </AppLegalDataProcessingAgreementWpper>
  )
}
